import React from 'react';

function TermsOfService() {
  return (
    <div className="terms-of-service">
      <div className="container">
        <h1>Terms of Service</h1>
        <p>Last updated: 08/21/2024</p>
        
        <h2>1. Acceptance of Terms</h2>
        <p>By accessing and using the services provided by MindCloud ("we", "our", or "us"), you agree to comply with and be bound by these Terms of Service.</p>
        
        <h2>2. Description of Service</h2>
        <p>MindCloud provides cloud infrastructure, DevOps, and related consulting services. We reserve the right to modify, suspend or discontinue our services at any time without notice.</p>
        
        <h2>3. User Responsibilities</h2>
        <p>You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p>
        
        <h2>4. Intellectual Property</h2>
        <p>The content, organization, graphics, design, and other matters related to our services are protected under applicable copyrights and other proprietary laws. The copying, redistribution, use or publication by you of any such matters or any part of our services is strictly prohibited.</p>
        
        <h2>5. Limitation of Liability</h2>
        <p>MindCloud shall not be liable for any direct, indirect, incidental, special or consequential damages resulting from the use or inability to use our services.</p>
        
        <h2>6. Indemnification</h2>
        <p>You agree to indemnify and hold MindCloud harmless from any claim or demand made by any third party due to or arising out of your use of our services.</p>
        
        <h2>7. Governing Law</h2>
        <p>These Terms of Service shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.</p>
        
        <h2>8. Changes to Terms</h2>
        <p>We reserve the right to modify these Terms of Service at any time. We will notify users of any changes by posting the new Terms of Service on this page.</p>
        
        <h2>9. Contact Us</h2>
        <p>If you have any questions about these Terms of Service, please contact us at: [Your Contact Information]</p>
      </div>
    </div>
  );
}

export default TermsOfService;