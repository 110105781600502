import React from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
  return (
    <nav className="navbar">
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="logo-container">
            <img 
              src="/mindcloud-logo.png" 
              alt="MindCloud Logo" 
              className="navbar-logo"
            />
            <span className="navbar-brand-text">
              <span className="brand-mind">Mind</span>
              <span className="brand-cloud">Cloud</span>
            </span>
          </Link>
        </div>
        <ul className="navbar-links">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about">About</Link></li>
          <li><Link to="/services">Services</Link></li>
          <li><Link to="/contact">Contact</Link></li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;