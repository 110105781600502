import React from 'react';
import { Link } from 'react-router-dom';
import { Code, GitBranch, Server, Cloud, BarChart2, DollarSign } from 'react-feather';

function Home() {
  const services = [
    { 
      icon: Code, 
      title: 'DevOps and Automation', 
      description: 'Streamline your development pipeline with end-to-end DevOps practices and automation, from code commit to deployment and beyond.'
    },
    { 
      icon: GitBranch, 
      title: 'CI/CD and Site Reliability', 
      description: 'Implement robust CI/CD pipelines and SRE practices to ensure your systems are always available, scalable, and performing at their peak.'
    },
    { 
      icon: Server, 
      title: 'Infrastructure as Code', 
      description: 'Leverage IaC to manage and provision your infrastructure consistently and efficiently across all environments.'
    },
    { 
      icon: Cloud, 
      title: 'Multi-Cloud Orchestration', 
      description: 'Seamlessly integrate and manage hybrid and multi-cloud environments (AWS, Azure, GCP) for optimal performance and flexibility.'
    },
    { 
      icon: BarChart2, 
      title: 'Observability and Monitoring', 
      description: 'Gain deep insights into your entire stack with comprehensive observability and advanced monitoring solutions.'
    },
    { 
      icon: DollarSign, 
      title: 'Cloud Cost Optimization', 
      description: 'Maximize your cloud ROI with expert analysis, optimization strategies, and cost-effective architectural decisions.'
    }
  ];

  return (
    <div className="home">
      <header className="hero">
        <div className="container">
          <h1>Elevate Your Enterprise Infrastructure</h1>
          <p>Streamlined Processes, Optimized Costs: Your Cloud, Perfected</p>
          <div className="cta-buttons">
            <Link to="/contact" className="btn btn-primary btn-rounded">Schedule a Consultation</Link>
            <Link to="/services" className="btn btn-secondary btn-rounded">Explore Services</Link>
          </div>
        </div>
      </header>

      <section className="introduction">
        <div className="container">
          <h2>Transforming and Optimizing Enterprise Infrastructure</h2>
          <p>
            At MindCloud, we specialize in revolutionizing enterprise-level cloud infrastructures through cutting-edge DevOps, 
            Site Reliability Engineering (SRE), and Platform Engineering solutions. Our team of experts is dedicated to 
            empowering your business with innovative, secure, and cost-effective infrastructure management.
          </p>
          <p>
            Whether you're looking to optimize your current systems, migrate to the cloud, implement advanced DevOps practices, 
            or reduce your cloud spending, MindCloud provides tailored solutions to meet your unique needs. We integrate security 
            and cost optimization at every level, ensuring your infrastructure is not just powerful and scalable, but also 
            protected and economically efficient.
          </p>
          <p>
            With a focus on data-driven decision making and continuous improvement, we help you navigate the complex landscape of 
            modern IT infrastructure. Let MindCloud be your partner in achieving unparalleled efficiency, reliability, performance, 
            and cost-effectiveness in your technology stack.
          </p>
        </div>
      </section>

      <section className="services">
        <div className="container">
          <h2>Our Core Services</h2>
          <div className="service-grid">
            {services.map((service, index) => (
              <div key={index} className="service-card">
                <service.icon className="service-icon" />
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="cta">
        <div className="container">
          <h2>Ready to Transform Your Infrastructure?</h2>
          <p>Let's discuss how our services can address your specific needs and challenges.</p>
          <Link to="/contact" className="btn btn-primary btn-rounded">Get in Touch</Link>
        </div>
      </section>
    </div>
  );
}

export default Home;