import React, { useState } from 'react';
import { Mail, Phone, MapPin } from 'react-feather';
import axios from 'axios';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const apiUrl = process.env.NODE_ENV === 'production' 
  ? '/api' 
  : process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus('sending');
    try {
      await await axios.post(`${apiUrl}/contact`, formData);
      setSubmitStatus('success');
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error');
    }
  };

  return (
    <div className="contact-page">
      <header className="page-header">
        <div className="container">
          <h1>Contact Us</h1>
        </div>
      </header>

      <section className="contact-content">
        <div className="container">
          <div className="contact-grid">
            <div className="contact-info">
              <h2>Get in Touch</h2>
              <p>We're here to help with your infrastructure needs. Reach out to us through any of the following methods:</p>
              <div className="contact-methods">
                <div className="contact-method">
                  <Mail size={24} />
                  <div>
                    <h3>Email</h3>
                    <p>info@mindcloudtech.com</p>
                  </div>
                </div>
                <div className="contact-method">
                  <Phone size={24} />
                  <div>
                    <h3>Phone</h3>
                    <p>+1 (555) 123-4567</p>
                  </div>
                </div>
                <div className="contact-method">
                  <MapPin size={24} />
                  <div>
                    <h3>Address</h3>
                    <p>123 Tech Avenue, Innovation City, TC 12345</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-form">
              <h2>Send Us a Message</h2>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="message">Message</label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-primary" disabled={submitStatus === 'sending'}>
                  {submitStatus === 'sending' ? 'Sending...' : 'Send Message'}
                </button>
              </form>
              {submitStatus === 'success' && <p className="success-message">Message sent successfully!</p>}
              {submitStatus === 'error' && <p className="error-message">There was an error sending your message. Please try again.</p>}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;