import React from 'react';
import { Code, GitBranch, Server, Cloud, BarChart2, DollarSign } from 'react-feather';

function Services() {
  const services = [
    { 
      icon: Code, 
      title: 'DevOps and Automation', 
      description: 'Streamline your development pipeline with end-to-end DevOps practices and automation. We implement cutting-edge tools and methodologies to enhance collaboration between development and operations teams, accelerate software delivery, and improve overall system reliability.',
      details: [
        'Continuous Integration and Continuous Deployment (CI/CD) pipeline setup',
        'Infrastructure automation',
        'Configuration management',
        'Containerization and orchestration with Docker and Kubernetes',
        'Automated testing and quality assurance'
      ]
    },
    { 
      icon: GitBranch, 
      title: 'CI/CD and Site Reliability Engineering', 
      description: 'Implement robust CI/CD pipelines and SRE practices to ensure your systems are always available, scalable, and performing at their peak. Our approach combines automated deployments with proactive system management to minimize downtime and maximize efficiency.',
      details: [
        'Design and implementation of CI/CD workflows',
        'Automated rollback and recovery strategies',
        'Service level objective (SLO) definition and tracking',
        'Incident response and postmortem processes',
        'Capacity planning and performance optimization'
      ]
    },
    { 
      icon: Server, 
      title: 'Infrastructure as Code', 
      description: 'Leverage Infrastructure as Code (IaC) to manage and provision your infrastructure consistently and efficiently across all environments. We help you transition from manual setups to automated, version-controlled infrastructure management.',
      details: [
        'IaC implementation with tools like Terraform or CloudFormation',
        'Version-controlled infrastructure',
        'Automated provisioning and deprovisioning',
        'Environment parity across development, staging, and production',
        'Infrastructure testing and validation'
      ]
    },
    { 
      icon: Cloud, 
      title: 'Multi-Cloud Orchestration', 
      description: 'Seamlessly integrate and manage hybrid and multi-cloud environments (AWS, Azure, GCP) for optimal performance and flexibility. Our expertise ensures you can leverage the best of each cloud provider while maintaining consistency and control.',
      details: [
        'Multi-cloud strategy development',
        'Cloud migration planning and execution',
        'Hybrid cloud architecture design',
        'Cross-cloud networking and security',
        'Multi-cloud monitoring and management'
      ]
    },
    { 
      icon: BarChart2, 
      title: 'Observability and Monitoring', 
      description: 'Gain deep insights into your entire stack with comprehensive observability and advanced monitoring solutions. We implement tools and practices that provide real-time visibility into your systems health, performance, and user experience.',
      details: [
        'Implementation of observability platforms (e.g., Prometheus, Grafana)',
        'Log aggregation and analysis',
        'Application performance monitoring',
        'Real-time alerting and notification systems',
        'Custom dashboard creation for operational insights'
      ]
    },
    { 
      icon: DollarSign, 
      title: 'Cloud Cost Optimization', 
      description: 'Maximize your cloud ROI with our expert analysis, optimization strategies, and cost-effective architectural decisions. We help you reduce waste, right-size resources, and implement efficient cloud spending practices without compromising performance.',
      details: [
        'Cloud spend analysis and forecasting',
        'Resource rightsizing recommendations',
        'Reserved instance and savings plans optimization',
        'Automated cost allocation and tagging strategies',
        'Continuous cost optimization through FinOps practices'
      ]
    }
  ];

  return (
    <div className="services-page">
      <header className="page-header">
        <div className="container">
          <h1>Our Services</h1>
          <p>Comprehensive solutions to elevate and optimize your enterprise infrastructure</p>
        </div>
      </header>

      <section className="services-list">
        <div className="container">
          {services.map((service, index) => (
            <div key={index} className="service-item">
              <div className="service-header">
                <service.icon size={40} className="service-icon" />
                <h2>{service.title}</h2>
              </div>
              <p>{service.description}</p>
              <ul className="service-details">
                {service.details.map((detail, detailIndex) => (
                  <li key={detailIndex}>{detail}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </section>

      <section className="cta">
        <div className="container">
          <h2>Ready to Optimize Your Infrastructure?</h2>
          <p>Let's discuss how our comprehensive services can address your specific needs and challenges.</p>
          <a href="/contact" className="btn btn-primary btn-rounded">Get in Touch</a>
        </div>
      </section>
    </div>
  );
}

export default Services;