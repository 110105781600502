import React from 'react';
import { Link } from 'react-router-dom';
import { Facebook, Twitter, Linkedin, GitHub } from 'react-feather';

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          {/* <div className="footer-section">
            <h3>About MindCloud</h3>
            <p>Empowering enterprises with cutting-edge infrastructure solutions and DevOps practices.</p>
          </div> */}
          <div className="footer-section">
            <h3>Quick Links</h3>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/about">About Us</Link></li>
              <li><Link to="/services">Services</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </div>
          {/* <div className="footer-section">
            <h3>Resources</h3>
            <ul>
              <li><a href="/blog">Blog</a></li>
              <li><a href="/case-studies">Case Studies</a></li>
              <li><a href="/whitepapers">Whitepapers</a></li>
              <li><a href="/faq">FAQ</a></li>
            </ul>
          </div> */}
          <div className="footer-section">
            <h3>Contact Us</h3>
            <p>Email: info@mindcloudtech.com</p>
            <p>Ph: +1 (973) 786-3706</p>
            <p></p>
            <div className="social-icons">
              {/* <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><Facebook size={20} /></a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><Twitter size={20} /></a>
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><Linkedin size={20} /></a>
              <a href="https://github.com" target="_blank" rel="noopener noreferrer"><GitHub size={20} /></a> */}
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2023 MindCloud. All rights reserved.</p>
          <div>
            <a href="/privacy-policy">Privacy Policy</a> | <a href="/terms-of-service">Terms of Service</a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;