import React from 'react';
import { Shield, Zap, Cloud, DollarSign, BarChart2, Users } from 'react-feather';

function About() {
  const coreValues = [
    { icon: Shield, title: 'Security-First Approach', description: 'We integrate robust security measures at every level of our solutions.' },
    { icon: Zap, title: 'Continuous Innovation', description: 'We stay at the forefront of cloud technology to serve you better.' },
    { icon: Cloud, title: 'Cloud Expertise', description: 'Deep knowledge in AWS, Azure, and GCP for optimal multi-cloud solutions.' },
    { icon: DollarSign, title: 'Cost Optimization', description: 'We help you maximize ROI and reduce cloud spending without compromising performance.' },
    { icon: BarChart2, title: 'Data-Driven Decisions', description: 'Our strategies are backed by comprehensive data analysis and metrics.' },
    { icon: Users, title: 'Collaborative Approach', description: 'We work closely with your team, fostering knowledge transfer and long-term success.' }
  ];

  return (
    <div className="about-page">
      <header className="page-header">
        <div className="container">
          <h1>About MindCloud</h1>
          <p>Transforming Cloud Infrastructure Through Innovation and Optimization</p>
        </div>
      </header>

      <section className="our-mission">
        <div className="container">
          <h2>Our Mission</h2>
          <p>
            At MindCloud, we are dedicated to revolutionizing enterprise-level cloud infrastructures. Our mission is to empower businesses with innovative, secure, and cost-effective solutions that drive efficiency and growth. Through cutting-edge DevOps practices, Site Reliability Engineering (SRE), and Platform Engineering, we help organizations navigate the complexities of modern IT landscapes.
          </p>
        </div>
      </section>

      <section className="our-expertise">
        <div className="container">
          <h2>Our Expertise</h2>
          <p>
            We specialize in optimizing cloud infrastructure and improving processes through:
          </p>
          <ul>
            <li>Advanced DevOps implementation and automation</li>
            <li>Site Reliability Engineering (SRE) best practices</li>
            <li>Innovative Platform Engineering solutions</li>
            <li>Comprehensive cloud cost optimization strategies</li>
            <li>Multi-cloud and hybrid cloud orchestration</li>
            <li>Infrastructure as Code (IaC) and CI/CD pipeline optimization</li>
          </ul>
        </div>
      </section>

      <section className="core-values">
        <div className="container">
          <h2>Our Core Values</h2>
          <div className="values-grid">
            {coreValues.map((value, index) => (
              <div key={index} className="value-item">
                <value.icon size={40} className="value-icon" />
                <h3>{value.title}</h3>
                <p>{value.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="our-approach">
        <div className="container">
          <h2>Our Approach</h2>
          <p>
            We believe in a holistic approach to cloud infrastructure management. By combining technical expertise with strategic insight, we deliver solutions that not only solve immediate challenges but also position our clients for future success. Our focus on cost optimization and process improvement ensures that your cloud infrastructure is not just powerful and scalable, but also economically efficient.
          </p>
        </div>
      </section>

      <section className="cta">
        <div className="container">
          <h2>Ready to Optimize Your Cloud Infrastructure?</h2>
          <p>Let's discuss how we can help you achieve your goals and overcome your challenges.</p>
          <a href="/contact" className="btn btn-primary btn-rounded">Get in Touch</a>
        </div>
      </section>
    </div>
  );
}

export default About;